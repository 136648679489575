import { v4 as uuidv4 } from 'uuid';

import { Search, ProductListing, Error } from 'pages';

const ROUTER_CONFIG = [
  {
    path: '/',
    Component: Search,
    id: uuidv4(),
  },
  {
    path: '/product-listing',
    Component: ProductListing,
    id: uuidv4(),
  },
  {
    path: '/error',
    Component: Error,
    id: uuidv4(),
  },
];

export default ROUTER_CONFIG;
