import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import ROUTER_CONFIG from './routerConfig';

/**
 * @description - Entry point for registering a route.
 * @function Routes
 */

function Routes(props) {
  return (
    <Router>
      {/* Injecting Common routes */}
      {ROUTER_CONFIG.map(({ path, Component, id }) => (
        <Route exact path={path} component={Component} key={id} />
      ))}
    </Router>
  );
}

export default Routes;
