import React from 'react';

const ProductListing = () => {
  return (
    <div>
      <h1>Product Finder Listing</h1>
    </div>
  );
};

export default ProductListing;
